/*
 * @Description: 处理Promise返回中间件
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-20 11:20:34
 */
const to = function(promise) {
  return promise
    .then(res => [res, null])
    .catch(err => {
      if (!err.msg) err.msg = err
      return [null, err]
    })
}
export default to
