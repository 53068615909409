import config from './config/dashboard'
import axios from '@/utils/request'
export const getCollegeNews = params => axios.post(config.collegeNews, params)
export const myCollege = params => axios.post(config.myCollege, params)
export const orgChange = params => axios.post(config.orgChange, params)
export const recentVisitList = params => axios.post(config.recentVisitList, params)
export const addRecentVisit = params => axios.post(config.addRecentVisit, params)
export const quickList = params => axios.post(config.quickList, params)
export const addQuickList = params => axios.post(config.addQuickList, params)
export const delQuickItem = params => axios.post(config.delQuickItem, params)
