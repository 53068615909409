/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-21 08:45:51
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-14 15:46:08
 */
// 用户相关
export const LOGIN = 'LOGIN' // 登录
export const LOGOUT = 'LOGOUT' // 登出
export const SET_TOKEN = 'SET_TOKEN' // 设置用户信息
export const SET_USER_INFO = 'SET_USER_INFO' // 设置用户信息
export const SET_SIDE_OPENED = 'SET_SIDE_OPENED' // 设置侧边栏收起展开状态
export const SET_COLLEGE_INFO = 'SET_COLLEGE_INFO' // 设置书院信息
export const SET_QUESTION_BANK = 'SET_QUESTION_BANK' //设置试题库
