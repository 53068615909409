import Layout from '@/layout'
export default [
  {
    path: '/examination',
    name: 'examination',
    component: Layout,
    redirect: '/examination/list',
    meta: { title: '考试管理', icon: 'el-icon-tickets' },
    children: [
      {
        path: 'list',
        name: 'List',
        component: () => import('@/views/examination/test/list'),
        meta: { title: '考试列表' },
      },
      {
        path: 'createdTest',
        name: 'CreatedTest',
        component: () => import('@/views/examination/test/createdTest'),
        meta: { title: '创建考试' },
      },
      {
        path: 'testResult',
        name: 'TestResult',
        component: () => import('@/views/examination/test/testResult'),
        meta: { title: '考试成绩' },
      },
      {
        path: 'testDetail',
        name: 'TestDetail',
        component: () => import('@/views/examination/test/testDetail'),
        meta: { title: '考试详情' },
      },
      {
        path: 'querstionList',
        name: 'QuerstionList',
        component: () => import('@/views/examination/QuestionBank/questionBankList'),
        meta: { title: '选择题库' },
      },
      {
        path: 'questionBankDetail',
        name: 'QuestionBankDetail',
        component: () => import('@/views/examination/QuestionBank/questionBankDetail'),
        meta: { title: '题库详情' },
      },
      {
        path: 'questionBankList',
        name: 'QuestionBankList',
        component: () => import('@/views/examination/QuestionBank/questionBankList'),
        meta: { title: '考试题库' },
      },
      {
        path: 'quesManger',
        name: 'QuesManger',
        component: () => import('@/views/examination/QuesManger/index'),
        meta: { title: '问卷管理' },
      },
      {
        path: 'quesManger/add',
        name: 'QuesMangerAdd',
        component: () => import('@/views/examination/QuesManger/components/add'),
        meta: { title: '创建问卷' },
      },
      {
        path: 'quesManger/setting',
        name: 'QuesMangerSetting',
        component: () => import('@/views/examination/QuesManger/components/setting'),
        meta: { title: '问卷设置' },
      },
      {
        path: 'quesManger/preview',
        name: 'QuesMangerPreview',
        component: () => import('@/views/examination/QuesManger/tableQues/preview'),
        meta: { title: '问卷预览' },
      },
      {
        path: 'quesManger/downLoad',
        name: 'QuesMangerdownLoad',
        component: () => import('@/views/examination/QuesManger/tableQues/downLoad'),
        meta: { title: '查看下载答案' },
      },
      {
        path: 'custom',
        name: 'Custom',
        component: () => import('@/views/examination/test/custom'),
        meta: { title: '考试自定义设置' },
      },
      {
        path: 'random',
        name: 'Random',
        component: () => import('@/views/examination/test/random'),
        meta: { title: '考试随机设置' },
      },
      {
        path: 'evaluationList',
        name: 'evaluationList',
        component: () => import('@/views/examination/Evaluation/list'),
        meta: { title: '测评记录' },
      },
      {
        path: 'evaluationDetail',
        name: 'evaluationDetail',
        component: () => import('@/views/examination/Evaluation/detail'),
        meta: { title: '个人测评详情' },
      },
      {
        path: 'evaluationDetail',
        name: 'evaluationDetail',
        component: () => import('@/views/examination/Evaluation/detail'),
        meta: { title: '个人测评详情' },
      },
    ],
  },
]
