<template>
  <div class="helpManual" @click="helpManual">
    <div><i class="el-icon-question iconFont"></i></div>
    <div class="help">帮助手册</div>
  </div>
</template>

<script>
export default {
  methods: {
    async helpManual() {
      let src =
        'https://ironge-pro.obs.cn-north-4.myhuaweicloud.com/frontend/help-book/%E6%98%8E%E5%95%86%E9%81%93%EF%BC%88B%E7%AB%AF%EF%BC%89-%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%28%E5%AE%9A%E7%A8%BF%29.pdf'
      const response = await fetch(src)
      const blob = await response.blob()
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '帮助手册'
      a.click()
      window.URL.revokeObjectURL(url)
    },
  },
}
</script>

<style scoped lang="scss">
.helpManual {
  display: flex;
  color: #606266;
  font-size: 14px;
  .help {
    margin-left: 5px;
    line-height: 68px;
  }
}
</style>
