/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-11-19 17:06:50
 */
import Layout from '@/layout'
import FullLayout from '@/layout/FullLayout'

export default [
  {
    path: '/college',
    name: 'College',
    component: Layout,
    redirect: '/college/collegeSetting',
    meta: { title: '书院管理', icon: 'el-icon-setting' },
    children: [
      {
        path: 'collegeSetting',
        name: 'CollegeSetting',
        component: () => import('@/views/college/CollegeSetting'),
        meta: { title: '书院设置', level: 1 },
      },
      {
        path: 'accountsManage',
        name: 'AccountsManage',
        component: () => import('@/views/college/Accounts/accountsManage'),
        meta: { title: '账户管理', level: 1 },
      },
      {
        path: 'withdrawal',
        name: 'Withdrawal',
        hidden: true,
        component: () => import('@/views/college/Accounts/withdrawal'),
        meta: { title: '提现' },
      },
      {
        path: 'upgradeVersion',
        name: 'UpgradeVersion',
        hidden: true,
        component: () => import('@/views/college/Accounts/upgradeVersion'),
        meta: { title: '升级版本' },
      },
      {
        path: 'addSeat',
        name: 'addSeat',
        hidden: true,
        component: () => import('@/views/college/Accounts/addSeat'),
        meta: { title: '增加席位' },
      },
      {
        path: 'orderPay/:buyNum/:orderNo/:year',
        name: 'orderPay',
        hidden: true,
        component: () => import('@/views/college/Accounts/orderPay'),
        meta: { title: '订单结算' },
      },
      {
        path: 'versionIntroduction',
        name: 'VersionIntroduction',
        hidden: true,
        component: () => import('@/views/college/Accounts/versionIntroduction'),
        meta: { title: '版本介绍' },
      },
      {
        path: 'wxpay',
        name: 'wxpay',
        hidden: true,
        component: () => import('@/views/college/Accounts/wxpay'),
        meta: { title: '支付订单' },
      },
      {
        path: 'payStatus',
        name: 'payStatus',
        hidden: true,
        component: () => import('@/views/college/Accounts/payStatus'),
        meta: { title: '支付' },
      },
      {
        path: 'collegeBanner',
        name: 'CollegeBanner',
        component: () => import('@/views/college/Banner/collegeBanner'),
        meta: { title: 'Banner图管理', level: 1 },
      },
      {
        path: 'addCollegeBanner',
        name: 'AddCollegeBanner',
        hidden: true,
        component: () => import('@/views/college/Banner/addCollegeBanner'),
        meta: { title: '新增Banner' },
      },
      {
        path: 'editCollegeBanner',
        name: 'EditCollegeBanner',
        hidden: true,
        component: () => import('@/views/college/Banner/editCollegeBanner'),
        meta: { title: 'Banner编辑' },
      },
      {
        path: 'collegeNotice',
        name: 'CollegeNotice',
        component: () => import('@/views/college/CollegeNotice'),
        meta: { title: '书院公告', level: 1 },
      },
      {
        path: 'admin',
        name: 'CollegeAdmin',
        component: () => import('@/views/college/CollegeAdmin/index'),
        meta: { title: '管理员管理', level: 1 },
      },
      {
        path: 'role',
        name: 'CollegeRole',
        component: () => import('@/views/college/CollegeRole/role'),
        meta: { title: '角色管理', level: 1 },
      },
      {
        path: 'getFreeCourse',
        name: 'GetFreeCourse',
        hidden: true,
        component: () => import('@/views/college/Accounts/getFreeCourse'),
        meta: { title: '领取课程' },
      },
      {
        path: 'CollegeRealName',
        name: 'CollegeRealName',
        hidden: true,
        component: () => import('@/views/college/CollegeRealName/CollegeRealName'),
        meta: { title: '实名认证企业书院主体' },
      },
      {
        path: 'learningAbility',
        name: 'LearningAbility',
        hidden: true,
        component: () => import('@/views/college/Accounts/learningAbility'),
        meta: { title: '同等学力介绍' },
      },
      {
        path: 'liveIntroduction',
        name: 'liveIntroduction',
        hidden: true,
        component: () => import('@/views/college/Accounts/liveIntroduction'),
        meta: { title: '直播服务介绍' },
      },
      {
        path: 'updateSuccess',
        name: 'updateSuccess',
        hidden: true,
        component: () => import('@/views/college/Accounts/updateSuccess'),
        meta: { title: '支付成功' },
      },
    ],
  },

  {
    path: '/college',
    name: 'College',
    component: FullLayout,
    redirect: '/college/collegeSetting',
    meta: { title: '书院管理', icon: 'el-icon-setting' },
    children: [
      {
        path: 'customizedCourse',
        name: 'CustomizedCourse',
        component: () => import('@/views/college/Accounts/CustomizedCourse/index'),
        meta: { title: '定制课程' },
      },
    ],
  },
]
