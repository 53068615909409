/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-22 11:46:10
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-05-30 15:24:56
 */
import Layout from '@/layout'

export default [
  {
    path: '/certCenter',
    name: 'CertCenter',
    component: Layout,
    redirect: '/certCenter/certManger',
    meta: { title: '证书中心', icon: 'el-icon-reading' },
    children: [
      {
        path: 'certManger',
        name: 'CertMangerList',
        component: () => import('@/views/certCenterManger/certManger/index'),
        meta: { title: '证书管理' },
      },
      {
        path: 'add',
        name: 'certMangerAdd',
        component: () => import('@/views/certCenterManger/certManger/add'),
        meta: { title: '创建证书' },
      },
      {
        path: 'add/:certId/:isEdit',
        name: 'certMangerEdit',
        component: () => import('@/views/certCenterManger/certManger/add'),
        meta: { title: '编辑证书' },
      },
      {
        path: 'addCourse/:certPrivateTrainId',
        name: 'ClassAddCourseByLibrary',
        hidden: true,
        component: () =>
          import(
            '@/views/certCenterManger/certTrainManager/components/AddCourse/AddCourseByLibrary/index'
          ),
        meta: { title: '从课程库中选择' },
      },
      {
        path: 'appoint/:certId',
        name: 'certMangerAppoint',
        component: () => import('@/views/certCenterManger/certManger/appoint'),
        meta: { title: '指定证书报名' },
      },
      {
        path: 'detail',
        name: 'certMangerDetail',
        component: () => import('@/views/certCenterManger/certManger/detail'),
        meta: { title: '证书详情' },
      },
      {
        path: 'detailItem',
        name: 'DetailItem',
        component: () => import('@/views/certCenterManger/certManger/detailItem'),
        meta: { title: '报名详情' },
      },
      {
        path: 'certTrainManager',
        name: 'CertTrainManager',
        component: () => import('@/views/certCenterManger/certTrainManager/index'),
        meta: { title: '证书培训管理' },
      },
      {
        path: 'certTrainManager/:classCertId/:isFound',
        name: 'CertTrainManagerEdit',
        component: () => import('@/views/certCenterManger/certTrainManager/add'),
        meta: { title: '编辑证书培训' },
      },
      {
        path: 'certEnrollManager',
        name: 'CertEnrollManager',
        component: () => import('@/views/certCenterManger/certEnrollManager/index'),
        meta: { title: '考证报名列表' },
      },
      {
        path: 'enrollDetail',
        name: 'EnrollDetail',
        component: () => import('@/views/certCenterManger/certEnrollManager/detail'),
        meta: { title: '报名资料' },
      },
      {
        path: 'uploadData',
        name: 'uploadData',
        component: () => import('@/views/certCenterManger/certEnrollManager/uploadData'),
        meta: { title: '报名资料' },
      },
      {
        path: 'enrollImportCert',
        name: 'EnrollImportCert',
        component: () => import('@/views/certCenterManger/certEnrollManager/importCert'),
        meta: { title: '导入成绩与证书' },
      },
      {
        path: 'certStudyStatiscs',
        name: 'CertStudyStatiscs',
        component: () => import('@/views/certCenterManger/certStudyStatiscs/index'),
        meta: { title: '学习统计' },
      },
      {
        path: 'studydetail',
        name: 'StudyDetail',
        component: () => import('@/views/certCenterManger/certStudyStatiscs/detail'),
        meta: { title: '学习详情' },
      },
    ],
  },
]
