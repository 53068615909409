/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-14 15:45:34
 */
import * as type from '../action-types'
import { login } from '@/api/login'
import { Message } from 'element-ui'
import { setCookie, removeCookie,getCookie } from '@/utils/cookies'
import to from 'await-to'
export default {
  state: {
    token: '',
    userInfo: {},
    collegeInfo: {},
    questionBank: [],
  },
  mutations: {
    [type.SET_USER_INFO](state, payload) {
      state.userInfo = payload
      setCookie('userInfo', payload)
    },
    [type.SET_TOKEN](state, payload) {
      state.token = payload.token
      setCookie('myToken', payload.token)
    },
    [type.SET_COLLEGE_INFO](state, payload) {
      state.collegeInfo = JSON.parse(JSON.stringify(payload))
    },
    [type.SET_QUESTION_BANK](state, payload) {
      //0为赋值，1为添加，2为删除
      if (payload.type == 0) {
        state.questionBank = payload.list
      } else if (payload.type == 1) {
        payload.list.map(i => {
          if (JSON.stringify(state.questionBank).indexOf(i.questionStoreId) == -1) {
            state.questionBank.push(i)
          }
        })
      } else {
        state.questionBank.splice(payload.index, 1)
      }
      console.log(state.questionBank)
    },
  },
  actions: {
    async [type.LOGIN]({ commit }, formData) {
      const [res, err] = await to(login(formData))
      if (err) return Message.warning(err.msg)
      commit(type.SET_USER_INFO, res.data)
      return res
    },
    async [type.LOGOUT]({ commit }) {
      if(getCookie("pulic")){
        commit(type.SET_USER_INFO, {})
        var keys = document.cookie.match(/[^ =;]+(?==)/g)
        if (keys) {
          keys.map(v => {
            removeCookie(v)
          })
        }
        sessionStorage.clear()
        window.location.href = 'https://pa.i-ronge.com/publicClass'
      }else{
        commit(type.SET_USER_INFO, {})
        var keys = document.cookie.match(/[^ =;]+(?==)/g)
        if (keys) {
          keys.map(v => {
            removeCookie(v)
          })
        }
        sessionStorage.clear()
        window.location.href = process.env.VUE_APP_FRONT_URL + 'introduceHome'
      }
      
    },
  },
}
