<!--
 * @Description: 公共布局
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-10-26 16:27:31
-->
<template>
  <div class="wrapper" :class="{ closeBar: opened }">
    <MHeader />
    <div class="wrapper_main">
      <SideMenu />
      <PageMain />
    </div>
  </div>
</template>

<script>
import SideMenu from './components/SideMenu'
import MHeader from './components/Header'
import PageMain from './components/PageMain'
import { mapGetters } from 'vuex'
export default {
  name: 'Layout',
  components: {
    SideMenu,
    MHeader,
    PageMain,
  },
  computed: {
    ...mapGetters(['opened']),
  },
}
</script>
<style lang="scss" scoped>
.pageMain {
  margin-left: 200px;
  transition: all 0.3s;
  box-sizing: border-box;
}
</style>
