import Layout from '@/layout'
export default [
  {
    path: '/live',
    name: 'Live',
    component: Layout,
    redirect: '/live/liveList',
    meta: { title: '直播管理', icon: 'el-icon-setting' },
    children: [
      {
        path: 'liveList',
        name: 'LiveList',
        component: () => import('@/views/live/liveList'),
        meta: { title: '直播列表', level: 1 },
      },
      {
        path: 'live/add',
        name: 'Add',
        component: () => import('@/views/live/add'),
        meta: { title: '创建直播', level: 1 },
      },
      {
        path: 'live/edit/:liveId',
        name: 'Edit',
        hidden: true,
        component: () => import('@/views/live/add'),
        meta: { title: '编辑直播' },
      },
      {
        path: 'liveDetail',
        name: 'LiveDetail',
        hidden: true,
        component: () => import('@/views/live/liveDetail'),
        meta: { title: '直播详情' },
      },
      {
        path: 'livePlayback',
        name: 'LivePlayback',
        hidden: true,
        component: () => import('@/views/live/livePlayback'),
        meta: { title: '直播回放' },
      },
      {
        path: 'liveMeal',
        name: 'LiveMeal',
        hidden: true,
        component: () => import('@/views/live/liveMeal'),
        meta: { title: '直播套餐' },
      },
    ],
  },
]
