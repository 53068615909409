/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-22 10:48:18
 */
import Layout from '@/layout'

export default [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import(/*webpackChunkName:'Redirect'*/ '@/views/redirect'),
      },
    ],
  },
  {
    path: '/exception',
    name: 'ErrorPage',
    component: Layout,
    type: 'system',
    children: [
      {
        path: '404',
        name: '404',
        component: () => import(/* webpackChunkName: '404' */ '@/views/error/404.vue'),
        hidden: true,
        meta: { title: '404' },
      },
    ],
  },
  {
    path: '*',
    name: '*404',
    redirect: '/exception/404',
    hidden: true,
  },
]
