/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-23 17:12:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-06-21 16:49:43
 */
export default {
  searchCollege: '/course/manage/org/tenant',
  editCollege: '/uc/identify/org/update',
  searchNoticeList: '/course/manage/announcement/query/list',
  noticeListDelete: '/course/manage/announcement/delete/batch',
  noticeListEdit: '/course/manage/announcement/edit',
  noticeAdd: '/course/manage/announcement/create',
  noticeViews: '/course/manage/announcement/update/pageviews',
  bannerList: '/course/manage/adbanner/query/list',
  bannerType: '/course/manage/adbanner/query/marks',
  bannerEditSearch: '/course/manage/adbanner/query',
  bannerListEdit: '/course/manage/adbanner/edit',
  bannerListDel: '/course/manage/adbanner/delete',
  bannerAdd: '/course/manage/adbanner/create',
  accountsMoney: '/order/identify/amount/show',
  seat: '/course/manage/org/current/member',
  seatOrder: '/course/manage/org/renew/order',
  seatPay: '/course/manage/org/renew/pay',
  charge: '/course/manage/org/version/items',
  versionUpdate: '/course/manage/org/version/update/pay',
  updateOrder: '/course/manage/org/version/update/order',
  enterpriseRecharge: '/order/identify/orgOrder/enterprise/invest',
  codePay: '/order/identify/orgOrder/enterprise/orderConfirm',
  menu: '/course/manage/menu/query/user/tree',
  roleMenu: '/security/identify/menu/query/tree', //角色授权tree
  adminList: '/course/manage/admin/page',
  adminAdd: '/course/manage/admin/batch/add/from/student',
  allRole: '/security/identify/role/query/page',
  addRole: '/security/identify/role/create',
  editRole: '/security/identify/role/edit',
  batchDelRole: '/security/identify/role/delete/batch',
  roleJurisdiction: '/security/identify/role/permission/query',
  sureJurisdiction: '/security/identify/role/permission/create',
  userAdmin: '/security/identify/user/role/create/batch',
  versionHistory: '/course/manage/org/version/history',
  productGivePage: '/course/manage/product/mgr/give/page',
  productGive: '/course/manage/product/mgr/give/receive',
  searchSuperAdmin: '/course/manage/auth/query', //查询是否是超级管理员
  adminDelete: '/course/manage/admin/batch/delete',
  getGiveDetail: '/course/manage/product/mgr/give/receive/detail',
  addTenant: '/course/manage/org/tenant/add',
  watermark: '/course/manage/image/watermark/upload/and/get/url',
}
