<!--
 * @Description: 全屏
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-20 11:15:44
-->
<template>
  <div v-if="isEnabled" class="full-screen">
    <div class="show" @click="toggleFull">
      <div><i class="el-icon-full-screen iconFont"></i></div>
      <div class="text">全屏显示</div>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
export default {
  computed: {
    isEnabled() {
      return screenfull.isEnabled
    },
  },
  methods: {
    toggleFull() {
      screenfull.toggle()
    },
  },
}
</script>
<style scoped lang="scss">
.show {
  display: flex;
  color: #606266;
  font-size: 14px;
  line-height: 68px;
  .text {
    margin-left: 5px;
  }
}
</style>
