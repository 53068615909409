<!--
 * @Description: 用户右上角信息下拉菜单
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-06-02 09:56:39
-->
<template>
  <div class="user-dropdown">
    <el-dropdown>
      <div class="user-info">
        <img :src="userInfo.headPic" :onerror="defaultAvatar" />
        <span class="name">
          {{ userInfo.userName }}
          <i class="el-icon-caret-bottom"></i>
        </span>
      </div>
      <el-dropdown-menu slot="dropdown" class="user-info-dropdown">
        <!-- <el-dropdown-item>
          <a @click="toFront"><i class="el-icon-s-home"></i>首页</a>
        </el-dropdown-item> -->
        <el-dropdown-item>
          <a @click="logout"><i class="el-icon-switch-button"></i>退出登录</a>
        </el-dropdown-item>
        <el-dropdown-item>
          <a @click="openMyOrg"><i class="el-icon-menu"></i>切换书院</a>
          <!-- <el-popover placement="left" trigger="hover">
            <div v-for="(item, index) in organization" :key="index" class="showorg">
              <a @click="changeMyorg(item.organizationId)">{{ item.organizationName }}</a>
            </div>
            <div slot="reference" class="reference"><i class="el-icon-menu"></i>切换书院</div>
          </el-popover> -->
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <changeOrg ref="changeOrg" :org-list="organization" />
  </div>
</template>

<script>
import * as type from '@/store/action-types'
import { mapGetters, createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('user')
import { myCollege, orgChange } from '@/api/dashboard'
import changeOrg from '../ChangeOrg'
import to from 'await-to'
export default {
  components: { changeOrg },
  data() {
    return {
      defaultAvatar: `this.src="${require('./avatar.png')}"`,
      organization: [],
      pager: {
        current: 1,
        size: 100,
      },
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.myorgData()
  },
  methods: {
    ...mapActions([type.LOGOUT]),
    logout() {
      this[type.LOGOUT]()
    },
    async myorgData() {
      const [res, err] = await to(myCollege({ ...this.pager }))
      if (err) return this.$message.warning(err.msg)
      this.organization = res.data.list
    },
    async changeMyorg(val) {
      const [, err] = await to(orgChange({ organizationId: val }))
      if (err) return this.$message.warning(err.msg)
      location.reload()
    },
    openMyOrg() {
      this.$refs.changeOrg.open()
    },
  },
}
</script>
<style lang="scss" scoped>
.user-info-dropdown {
  overflow: hidden;
  ::v-deep .el-dropdown-menu__item {
    padding: 0;
    a {
      display: block !important;
      padding: 0 20px;
    }
  }
}
.showorg a {
  display: block;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  border: 1px solid #e2e2e2;
}
.showorg a:hover {
  background: #b6927f;
  color: #fff;
}
.reference {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
