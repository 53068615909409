import Layout from '@/layout'

export default [
  {
    path: '/finance',
    name: 'Finance',
    component: Layout,
    redirect: '/finance/orderList',
    meta: { title: '财务管理', icon: 'el-icon-menu' },
    children: [
      {
        path: 'orderList',
        name: 'OrderList',
        component: () => import('@/views/finance/Order/orderList'),
        meta: { title: '订单管理' },
      },
      {
        path: 'detail',
        name: 'Detail',
        component: () => import('@/views/finance/Order/detail'),
        meta: { title: '订单详情' },
        hidden: true,
      },
      {
        path: 'incomeList',
        name: 'IncomeList',
        component: () => import('@/views/finance/Income/incomeList'),
        meta: { title: '收支明细' },
      },
    ],
  },
]
