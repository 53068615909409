/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-22 11:46:10
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-09-28 17:15:57
 */
import Layout from '@/layout'
import PureLayout from '@/layout/PureLayout'

export default [
  {
    path: '/class',
    name: 'Class',
    component: Layout,
    redirect: '/class/list',
    meta: { title: '班级管理', icon: 'el-icon-menu' },
    children: [
      {
        path: 'list',
        name: 'ClassList',
        component: () => import(/* webpackChunkName: "ClassList" */ '@/views/class/index'),
        meta: { title: '班级列表', level: 1 },
      },
      {
        path: 'add',
        name: 'ClassAdd',
        component: () => import(/* webpackChunkName: "ClassAdd" */ '@/views/class/add'),
        meta: { title: '创建班级', level: 1 },
      },
      {
        path: 'edit/:classId',
        name: 'ClassEdit',
        hidden: true,
        component: () => import(/* webpackChunkName: "ClassEdit" */ '@/views/class/add'),
        meta: { title: '修改班级' },
      },
      {
        path: 'classDetail/:classId',
        name: 'ClassDetail',
        hidden: true,
        component: () => import(/* webpackChunkName: "ClassDetail" */ '@/views/class/detail'),
        meta: { title: '班级详情' },
      },
      {
        path: 'addStudent/:classId',
        name: 'ClassAddStudent',
        hidden: true,
        component: () =>
          import(/* webpackChunkName: "ClassAddStudent" */ '@/views/class/ClassAddStudent'),
        meta: { title: '添加员工' },
      },
      {
        path: 'addCourse/:classId',
        name: 'ClassAddCourse',
        hidden: true,
        component: () =>
          import(/* webpackChunkName: "ClassAddCourse" */ '@/views/class/ClassAddCourse'),
        meta: { title: '添加课程' },
      },
      {
        path: 'offer/:classId',
        name: 'ClassOffer',
        hidden: true,
        component: () => import(/* webpackChunkName: "ClassOffer" */ '@/views/class/offer'),
        meta: { title: '确认信息' },
      },
      {
        path: 'studyInfo/:classId/:className',
        name: 'ClassStudyInfo',
        hidden: true,
        component: () => import(/* webpackChunkName: "ClassStudyInfo" */ '@/views/class/studyInfo'),
        meta: { title: '学习详情' },
      },
      {
        path: 'evaluate/:classId',
        name: 'ClassEvaluate',
        hidden: true,
        component: () => import(/* webpackChunkName: "ClassEvaluate" */ '@/views/class/evaluate'),
        meta: { title: '评价记录' },
      },
      {
        path: 'studentDetail',
        name: 'StudentDetail',
        hidden: true,
        component: () =>
          import(/* webpackChunkName: "StudentDetail" */ '@/views/class/NewStudentDetail/index'),
        meta: { title: '个人学习详情' },
      },
      {
        path: 'addCourse/:classId/library',
        name: 'ClassAddCourseByLibrary',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "ClassAddCourseByLibrary" */ '@/views/class/ClassAddCourse/AddCourseByLibrary/index'
          ),
        meta: { title: '从课程库中选择' },
      },
      {
        path: 'addCourse/:classId/shop',
        name: 'ClassAddCourseByShop',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "ClassAddCourseByShop" */ '@/views/class/ClassAddCourse/AddCourseByShop'
          ),
        meta: { title: '从课程商城选择' },
      },
      {
        path: 'addCourse/:classId/plan',
        name: 'ClassAddCourseByPlan',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "ClassAddCourseByPlan" */ '@/views/class/ClassAddCourse/AddCourseByPlan'
          ),
        meta: { title: '从培养方案选择' },
      },
      {
        path: 'addCourse/plan/:trainPlanId',
        name: 'ClassAddCourseByPlanDetail',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "ClassAddCourseByPlanDetail" */ '@/views/class/ClassAddCourse/AddCourseByPlan/detail'
          ),
        meta: { title: '方案详情' },
      },
      {
        path: 'addCourse/exam/:classCourseId',
        name: 'ClassAddCourseExam',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "ClassAddCourseExam" */ '@/views/class/ClassAddCourse/AddCourseExam'
          ),
        meta: { title: '设置考试' },
      },
    ],
  },
  {
    path: '/class',
    name: 'Class',
    component: PureLayout,
    children: [
      {
        path: 'play',
        name: 'ClassPlay',
        component: () => import(/* webpackChunkName: "ClassPlay" */ '@/views/class/play'),
        meta: { title: '课程播放' },
      },
    ],
  },
]
