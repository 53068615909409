/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-22 09:49:48
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-28 14:28:55
 */
import Layout from '@/layout'

export default [
  {
    path: '/student',
    name: 'Student',
    component: Layout,
    redirect: '/student/list',
    meta: { title: '员工管理', icon: 'el-icon-user' },
    children: [
      {
        path: 'list',
        name: 'StudentList',
        component: () =>
          import(/* webpackChunkName: "StudentList" */ '@/views/student/StudentList/index'),
        meta: { title: '员工列表' },
      },
      {
        path: 'add',
        name: 'StudentAdd',
        hidden: true,
        component: () =>
          import(/* webpackChunkName: "StudentAdd" */ '@/views/student/StudentList/add'),
        meta: { title: '新增员工' },
      },
      {
        path: 'batchAdd',
        name: 'StudentBatchAdd',
        hidden: true,
        component: () =>
          import(/* webpackChunkName: "StudentBatchAdd" */ '@/views/student/StudentList/batchAdd'),
        meta: { title: '批量添加员工' },
      },
      {
        path: 'edit/:studentId',
        name: 'StudentEdit',
        hidden: true,
        component: () =>
          import(/* webpackChunkName: "StudentEdit" */ '@/views/student/StudentList/edit'),
        meta: { title: '编辑员工' },
      },
      {
        path: 'department',
        name: 'department',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "StudentEdit" */ '@/views/student/StudentList/department/index'
          ),
        meta: { title: '部门管理' },
      },
    ],
  },
]
