<!--
 * @Description: 面包屑
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-07-26 17:12:35
-->
<template>
  <div v-if="$route.path != '/dashbord'" class="bread-crumb">
    <el-breadcrumb separator=">>">
      <transition-group name="breadcrumb">
        <el-breadcrumb-item
          v-for="(item, index) in breadList"
          :key="item.path"
          :to="index != breadList.length - 1 ? item.path : ''"
        >
          {{ item.meta.title }}
        </el-breadcrumb-item>
      </transition-group>
    </el-breadcrumb>
    <!-- <div v-for="(item, index) in arr" :key="index">
      <div @click="goPath(item, index)">
        {{ index == arr.length - 1 ? item.name : item.name + '>>' }}
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadList: [],
      arr: [],
    }
  },
  watch: {
    $route: {
      handler(route) {
        let allList = route.matched.filter(item => {
          if (item.meta && item.meta.title) {
            // if (item.redirect) {
            //   item.path = ''
            // }
            return true
          }
        })

        if (allList.length && allList[0].path !== '/' && allList[0].path !== '/dashbord') {
          allList.unshift({ path: '/', meta: { title: '首页' } })
        }
        this.breadList = allList

        if (!sessionStorage.getItem('parentName')) {
          sessionStorage.setItem('parentName', JSON.stringify(route.matched[0].path))
        }
        if (!JSON.parse(sessionStorage.getItem('breadCrumbArr'))) {
          sessionStorage.setItem(
            'breadCrumbArr',
            JSON.stringify([{ name: '首页', path: '/dashbord' }]),
          )
        }

        let str = route.matched[1].path.split('/')[1]
        if (route.path !== '/dashbord') {
          if (JSON.parse(sessionStorage.getItem('parentName')) == '/' + str) {
            this.setBreadCrumbArr(route)
          } else {
            sessionStorage.setItem('parentName', JSON.stringify(route.matched[0].path))
            sessionStorage.setItem(
              'breadCrumbArr',
              JSON.stringify([{ name: '首页', path: '/dashbord' }]),
            )
            this.setBreadCrumbArr(route)
          }
        } else {
          sessionStorage.setItem(
            'breadCrumbArr',
            JSON.stringify([{ name: '首页', path: '/dashbord' }]),
          )
        }
      },
      immediate: true,
    },
  },
  methods: {
    goPath(item, index) {
      if (index !== this.arr.length - 1) {
        this.$router.push(item.path)
        // window.location = item.path
      }
    },
    unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].name == arr[j].name) {
            //第一个等同于第二个，splice方法删除第二个
            arr.splice(j, 1)
            j--
          }
        }
      }
      return arr
    },
    setBreadCrumbArr(route) {
      // console.log(window.location.href)
      this.arr = JSON.parse(sessionStorage.getItem('breadCrumbArr'))
      let filterArr = route.matched.filter(v => v.meta.level == 1)
      if (filterArr.length !== 0) {
        this.arr = [{ name: '首页', path: '/dashbord' }]
        this.arr.push({
          name: filterArr[0].parent.meta.title,
          path: filterArr[0].parent.redirect,
          // path: window.location.href,
        })
        this.arr.push({ name: filterArr[0].meta.title, path: filterArr[0].path })
        // this.arr.push({ name: filterArr[0].meta.title, path: window.location.href })
        this.unique(this.arr)
        sessionStorage.setItem('breadCrumbArr', JSON.stringify(this.arr))
      } else {
        route.matched.map(item => {
          this.arr.push({
            name: item.meta.title,
            path: item.path,
            // path: window.location.href,
          })
        })
        this.unique(this.arr)
        sessionStorage.setItem('breadCrumbArr', JSON.stringify(this.arr))
      }
    },
  },
}
</script>
<style scoped lang="scss">
.bread-crumb {
  ::v-deep .el-breadcrumb__inner {
    color: #333 !important;
    font-weight: bold;
  }
  ::v-deep .is-link {
    font-weight: normal !important;
    color: #909399 !important;
  }
}
</style>
