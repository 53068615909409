/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-22 11:46:03
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-03-21 11:36:34
 */
import Layout from '@/layout'

export default [
  {
    path: '/course',
    name: 'Course',
    component: Layout,
    redirect: '/course/resources',
    meta: { title: '教学中心', icon: 'el-icon-files' },
    children: [
      {
        path: 'resources',
        name: 'CourseResources',
        component: () =>
          import(/* webpackChunkName: "CourseResources" */ '@/views/course/CourseResources'),
        meta: { title: '视频资源管理', level: 1 },
      },
      {
        path: 'resources/add',
        name: 'CourseResourcesAdd',
        hidden: true,
        component: () =>
          import(/* webpackChunkName: "CourseResourcesAdd" */ '@/views/course/CourseResources/add'),
        meta: { title: '添加课程资源' },
      },
      {
        path: 'resources/edit/:id',
        name: 'CourseResourcesEdit',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "CourseResourcesEdit" */ '@/views/course/CourseResources/add'
          ),
        meta: { title: '编辑课程资源' },
      },
      {
        path: 'resources/progress/:courseId',
        name: 'CourseProgress',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "CourseProgress" */ '@/views/course/CourseResources/progress'
          ),
        meta: { title: '上传进度' },
      },
      {
        path: 'library',
        name: 'CourseLibrary',
        component: () =>
          import(/* webpackChunkName: "CourseLibrary" */ '@/views/course/CourseLibrary'),
        meta: { title: '书院课程库', level: 1 },
      },
      {
        path: 'library/add',
        name: 'CourseLibraryAdd',
        hidden: true,
        component: () =>
          import(/* webpackChunkName: "CourseLibraryAdd" */ '@/views/course/CourseLibrary/add'),
        meta: { title: '创建课程' },
      },
      {
        path: 'library/edit/:courseId',
        name: 'CourseLibraryEdit',
        hidden: true,
        component: () =>
          import(/* webpackChunkName: "CourseLibraryEdit" */ '@/views/course/CourseLibrary/add'),
        meta: { title: '编辑课程' },
      },
      {
        path: 'library/section/:courseId',
        name: 'CourseLibrarySection',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "CourseLibrarySection" */ '@/views/course/CourseLibrary/section'
          ),
        meta: { title: '章节管理' },
      },
      {
        path: 'shop',
        name: 'CourseShop',
        component: () => import(/* webpackChunkName: "CourseShop" */ '@/views/course/CourseShop'),
        meta: { title: '课程商城', keepAlive: true, level: 1 },
      },
      {
        path: 'shop/:productId',
        name: 'CourseShopDetail',
        component: () =>
          import(/* webpackChunkName: "CourseShopDetail" */ '@/views/course/CourseShop/detail'),
        meta: { title: '课程详情' },
      },
      {
        path: 'shop/want/:productId',
        name: 'CourseWantDetail',
        component: () =>
          import(
            /* webpackChunkName: "CourseShopDetail" */ '@/views/course/CourseShop/wantCoursedetail'
          ),
        meta: { title: '课程详情' },
      },
      {
        path: 'play/:productId',
        name: 'CourseShopPlay',
        component: () =>
          import(/* webpackChunkName: "CourseShopPlay" */ '@/views/course/CourseShop/play'),
        meta: { title: '课程播放' },
      },
      {
        path: 'live/:productId',
        name: 'CourseShopLive',
        component: () =>
          import(/* webpackChunkName: "CourseShopLive" */ '@/views/course/CourseShop/live'),
        meta: { title: '直播详情' },
      },
      {
        path: 'collect',
        name: 'CourseCollect',
        component: () =>
          import(/* webpackChunkName: "CourseCollect" */ '@/views/course/CourseShop/collect'),
        meta: { title: '我的收藏夹' },
      },
      {
        path: 'group',
        name: 'Croup',
        component: () =>
          import(/* webpackChunkName: "CourseCollect" */ '@/views/course/CourseResources/group'),
        meta: { title: '视频分组' },
      },
      {
        path: 'public',
        name: 'PublicCourse',
        component: () =>
          import(/* webpackChunkName: "PublicCourse" */ '@/views/course/PublicCourse/index'),
        meta: { title: '公开课管理' },
      },
      {
        path: 'public/add',
        name: 'PublicCourseAdd',
        component: () =>
          import(
            /* webpackChunkName: "PublicCourseAdd" */ '@/views/course/PublicCourse/AddCourseByLibrary'
          ),
        meta: { title: '课程库中选择' },
      },
      {
        path: 'public/log',
        name: 'PublicCourseLog',
        component: () =>
          import(/* webpackChunkName: "PublicCourseLog" */ '@/views/course/PublicCourse/log'),
        meta: { title: '公开课学习记录' },
      },
    ],
  },
]
