/*
 * @Description: http code 错误处理
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-20 11:20:41
 */
export default {
  400: '请求错误',
  401: '未授权',
  403: '拒绝访问',
  404: '请求地址出错',
  406: '请求的格式不可得',
  408: '请求超时',
  500: '服务器内部错误',
  501: '服务未实现',
  502: '网关错误',
  503: '服务不可用，服务器暂时过载或维护',
  504: '网关超时',
  505: 'HTTP版本不受支持',
}
