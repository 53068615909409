/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-21 08:45:51
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-12-27 10:14:35
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import '@/styles/index.scss' // glob scss
import VueClipboard from 'vue-clipboard2'
import 'fullpage.js/vendors/scrolloverflow'
import VueFullPage from 'vue-fullpage.js'
import BaiduMap from 'vue-baidu-map'
import VueCropper from 'vue-cropper'
import VueLazyload from 'vue-lazyload'
Vue.use(VueCropper)
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'FhybqAkwHtDfug6rpSlWmoxkgbeO3SGn',
})
Vue.use(VueClipboard)
Vue.use(VueFullPage)
Vue.use(VueLazyload, {
  preLoad: 0.9,
  attempt: 1,
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
