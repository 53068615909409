const getters = {
  userInfo: state => state.user.userInfo,
  token: state => state.user.token,
  opened: state => {
    if (state.opened === 'false') {
      return false
    } else if (state.opened === 'true') {
      return true
    }
  },
}
export default getters
