/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-21 16:09:08
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import getTitle from '@/utils/get-title'
// import store from '@/store'
// import * as type from '@/store/action-types'
// import { getCookie } from '@/utils/cookies'
import 'nprogress/nprogress.css'
import Layout from '@/layout'
Vue.use(VueRouter)

let routes = [
  {
    path: '/',
    name: 'Home',
    component: Layout,
    redirect: '/dashbord',
    children: [
      {
        path: '/dashbord',
        name: 'Dashbord',
        component: () => import(/*webpackChunkName:'Dashboard'*/ '@/views/dashboard'),
        meta: { title: '首页', icon: 'el-icon-s-data', affix: true },
      },
      {
        path: '/dashbord/quick',
        name: 'Quick',
        hidden: true,
        component: () => import('@/views/dashboard/quick'),
        meta: { title: '添加快捷入口' },
      },
      {
        path: '/dashbord/allNews',
        name: 'AllNews',
        hidden: true,
        component: () => import('@/views/dashboard/allNews'),
        meta: { title: '全部动态' },
      },
    ],
  },
]
const files = require.context('./modules/', false, /\.router.js$/)
files.keys().forEach(key => {
  const file = files(key).default || files(key)
  routes.push(...file)
})
const router = new VueRouter({
  mode: 'history',
  routes,
  // 路由跳转后返回顶部
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
})

NProgress.configure({
  showSpinner: false,
})

router.beforeEach((to, from, next) => {
  document.title = getTitle(to.meta.title)
  NProgress.start()
  // if (to.path === '/login') return next()
  // if (!getCookie('myToken')) {
  //   next({
  //     path: '/login',
  //     query: {
  //       redirect: to.fullPath,
  //     },
  //   })
  // } else {
  //   // 重拿用户信息
  //   if (JSON.stringify(store.state.user.userInfo) == '{}') {
  //     const userInfo = getCookie('userInfo')
  //     JSON.stringify(userInfo) !== '{}' && store.commit(`user/${type.SET_USER_INFO}`, userInfo)
  //   }
  next()
  // }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

router.afterEach(() => {
  NProgress.done()
})
export default router
