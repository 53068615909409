<template>
  <el-dialog
    custom-class="change-org"
    title="所属书院列表"
    :visible.sync="visible"
    append-to-body
    width="580px"
  >
    <div class="content">
      <div class="btns">
        <el-button type="primary" @click="toHome"
          ><img src="@/assets/common/create_icon.png" alt="" />创建书院</el-button
        >
        <el-button @click="toHome">
          <img src="@/assets/common/user_icon.png" alt="" />加入其他书院</el-button
        >
      </div>
      <div class="org-list">
        <div
          v-for="item in orgList"
          :key="item.organizationId"
          v-lazy-container="{ selector: 'img' }"
          :class="['org-item', { select: selectOrgId == item.organizationId }]"
          @click="selectOrgId = item.organizationId"
        >
          <img :data-src="item.logo" alt="" draggable="false" />
          <div class="name">{{ item.organizationName }}</div>
          <div class="check">
            <!-- <img src="@/assets/common/tick_icon.png" alt="" /> -->
            <img src="@/assets/common/tick-select-icon.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="changeMyorg">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { orgChange } from '@/api/dashboard'
import to from 'await-to'
export default {
  name: 'ChangeOrg',
  props: {
    orgList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
      selectOrgId: 0,
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    async changeMyorg() {
      const [, err] = await to(orgChange({ organizationId: this.selectOrgId }))
      if (err) return this.$message.warning(err.msg)
      // location.reload()
      this.$nextTick(() => {
        window.location.href = window.location.origin
      })
    },
    toHome() {
      window.location.href = process.env.VUE_APP_FRONT_URL + '/introduceHome'
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .change-org {
  .el-dialog__header {
    padding: 0 20px;
    box-sizing: border-box;
    height: 55px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
    font-size: 16px;
    color: #333333;
  }
  .el-dialog__body {
    padding: 0;
  }
  .content {
    padding: 10px 20px 23px;
    .btns {
      text-align: right;
      margin-bottom: 20px;
      .el-button {
        height: 36px;
        &:first-child {
          width: 98px;
        }
        &:last-child {
          width: 125px;
        }
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            margin-right: 3px;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .org-list {
      width: 100%;
      height: 482px;
      padding-top: 3px;
      overflow-x: hidden;
      overflow-y: auto;
      .org-item {
        position: relative;
        cursor: pointer;
        float: left;
        box-sizing: border-box;
        text-align: center;
        width: 154px;
        height: 147px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #c2c2c2;
        padding-top: 20px;
        margin-right: 29px;
        margin-bottom: 20px;
        &:nth-of-type(3n) {
          margin: 0;
        }
        img {
          display: block;
          width: 70px;
          height: 70px;
          margin: 0 auto;
          margin-bottom: 10px;
        }
        .name {
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          width: 96%;
          margin: 0 auto;
          // @extend %one-line-text;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .check {
          position: absolute;
          right: 10px;
          top: 10px;
          width: 18px;
          height: 18px;
          background: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 2px;
          padding-top: 5px;
          box-sizing: border-box;
          img {
            width: 10px;
            height: 8px;
            opacity: 0;
          }
        }
        &.select {
          background: #ffede4;
          border: 1px solid #ff7b33;
          .check {
            border: 0;
            background: #ffede4;
            img {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    padding-right: 17px;
    justify-content: flex-end;
    height: 52px;
    border-top: 1px solid #e2e2e2;
    .el-button {
      width: 80px;
      height: 32px;
    }
  }
}
</style>
