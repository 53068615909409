export default {
  collegeNews: '/course/manage/homepage/org/dynamic/query', //书院动态
  myCollege: '/course/manage/org/myorg', //我的学院
  orgChange: '/uc/identify/org/change', //切换学院
  recentVisitList: '/course/manage/last/access/list', //最近访问
  addRecentVisit: '/course/manage/last/access/save', //添加最近访问
  quickList: '/course/manage/shortcut/list', //快接入口
  addQuickList: '/course/manage/shortcut/save', //添加快接入口
  delQuickItem: '/course/manage/shortcut/delete', //删除快接入口
}
