import config from './config/college'
import axios from '@/utils/request'
export const getSearchCollegeNews = params => axios.post(config.searchCollege, params) // 查询书院信息
export const getEditCollegeNews = params => axios.post(config.editCollege, params) // 編輯书院信息
export const getFileUpload = params => axios.post(`/third/api/huaWeiUpload/upload/img`, params) //上传图片
export const getCollegeNoticeList = params => axios.post(config.searchNoticeList, params) //公告列表
export const getCollegeNoticeListDel = params => axios.post(config.noticeListDelete, params) //公告列表删除
export const getCollegeNoticeListEdit = params => axios.post(config.noticeListEdit, params) //公告列表编辑
export const getCollegeNoticeListAdd = params => axios.post(config.noticeAdd, params) //公告列表添加
export const getCollegeNoticeViews = params => axios.post(config.noticeViews, params) //更新公告浏览量
export const getCollegeBannerListDel = params => axios.post(config.bannerListDel, params) //轮播图列表
export const getCollegeBannerList = params => axios.post(config.bannerList, params) //轮播图列表刪除
export const getCollegeBannerType = params => axios.post(config.bannerType, params) //轮播图位置
export const getCollegeBannerEditSearch = params => axios.post(config.bannerEditSearch, params) //轮播图編輯查詢
export const getCollegeBannerEdit = params => axios.post(config.bannerListEdit, params) //轮播图編輯
export const getCollegeBannerAdd = params => axios.post(config.bannerAdd, params) //轮播图編輯
export const getAccountsMoney = params => axios.post(config.accountsMoney, params) //账户余额
export const getAccountsSeat = params => axios.post(config.seat, params) //账户席位
export const getAccountsCharge = params => axios.post(config.charge, params) //升级企业详情
export const getAccountsUpdate = params => axios.post(config.versionUpdate, params) //升级企业支付
export const getAccountsSeatOrder = params => axios.post(config.seatOrder, params) //企业续费（增加席位）下单
export const getAccountsSeatPay = params => axios.post(config.seatPay, params) //企业续费（增加席位）支付
export const getEnterpriseRecharge = params => axios.post(config.enterpriseRecharge, params) //企业充值
export const getUpdateOrder = params => axios.post(config.updateOrder, params) //企业版下单
export const getWXCodePay = params => axios.post(config.codePay, params) //微信扫码支付
export const getMenu = params => axios.post(config.menu, params) //获取全部菜单
export const getAdminList = params => axios.post(config.adminList, params) //管理员列表
export const getAdminAddSubmit = params => axios.post(config.adminAdd, params) //管理员添加
export const getAllRole = params => axios.post(config.allRole, params) //全部角色
export const getAddRole = params => axios.post(config.addRole, params) //添加角色
export const getEditRole = params => axios.post(config.editRole, params) //编辑角色
export const getBatchDelRole = params => axios.post(config.batchDelRole, params) //删除角色
export const getRoleJurisdiction = params => axios.post(config.roleJurisdiction, params) //查詢角色角色权限
export const getSureJurisdiction = params => axios.post(config.sureJurisdiction, params) //确定授权
export const getUserAdmin = params => axios.post(config.userAdmin, params) //用户角色授权
export const getVersionHistory = params => axios.post(config.versionHistory, params) //账户购买记录
export const getProductGivePage = params => axios.post(config.productGivePage, params) //免费领取课程列表
export const productGive = params => axios.post(config.productGive, params) //免费领取课程
export const searchSuperAdmin = params => axios.post(config.searchSuperAdmin, params) //查询是否是超级管理员
export const adminDelete = params => axios.post(config.adminDelete, params) //批量删除管理员
export const getGiveDetail = params => axios.post(config.getGiveDetail, params) //企业赠送信息详情
export const addTenant = params => axios.post(config.addTenant, params) //添加主体信息
export const roleMenu = params => axios.post(config.roleMenu, params) //角色授权tree
export const getWatermark = params => axios.post(config.watermark, params) //获取水印图片地址
