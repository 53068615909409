<!--
 * @Description: 公共布局
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-20 11:17:45
-->
<template>
  <div class="wrapper" :class="{ closeBar: opened }">
    <MHeader />
    <div class="wrapper_main">
      <SideMenu />
      <div class="main-container">
        <BreadCrumb />
        <PageMain />
      </div>
    </div>
  </div>
</template>

<script>
import SideMenu from './components/SideMenu'
import MHeader from './components/Header'
import PageMain from './components/PageMain'
import BreadCrumb from './components/BreadCrumb'
import { mapGetters } from 'vuex'
export default {
  name: 'Layout',
  components: {
    SideMenu,
    MHeader,
    PageMain,
    BreadCrumb,
  },
  computed: {
    ...mapGetters(['opened']),
  },
}
</script>
