<!--
 * @Description: 公共头部
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-04-19 16:46:05
-->
<template>
  <div class="main-header">
    <div class="main-header-logo">
      <router-link to="/" class="logoLink">
        <img :src="collegeInfo.logo" alt="" />
        {{ collegeInfo.organizationName }}管理系统
      </router-link>
    </div>
    <div class="main-header-main">
      <div class="backHome" @click="toFront">
        <div><i class="el-icon-s-home iconFont"></i></div>
        <span>书院首页</span>
      </div>
      <Help></Help>
      <full-screen></full-screen>
      <user-dropdown></user-dropdown>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('user')
import UserDropdown from '../UserDropdown'
import FullScreen from '../FullScreen'
import Help from '../Help'
export default {
  components: {
    UserDropdown,
    FullScreen,
    Help,
  },
  computed: {
    ...mapState(['collegeInfo']),
  },
  methods: {
    toFront() {
      window.location.href = process.env.VUE_APP_FRONT_URL + 'index'
    },
  },
}
</script>
<style lang="scss" scoped>
.backHome {
  display: flex;
  color: #606266;
  font-size: 14px;
  span {
    margin-left: 5px;
    line-height: 68px;
  }
}
</style>
