<!--
 * @Description: 纯净布局
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-09-26 14:52:16
-->
<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'PureLayout',
}
</script>
