<!--
 * @Description: 
 * @Author: xiawenlong
 * @Date: 2021-04-21 08:45:51
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-09-06 09:31:57
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import * as type from '@/store/action-types'
import { getSearchCollegeNews, getMenu } from '@/api/college'
import { addRecentVisit } from '@/api/dashboard'
import { userInfo } from '@/api/login'
import { setCookie } from '@/utils/cookies'
import to from 'await-to'
export default {
  name: 'App',
  data() {
    return {
      arr: [],
      menuList: [],
      clientCode: '260bb55c-47e3-48d0-bd8c-c2cf1ce5',
    }
  },
  watch: {
    $route(to) {
      this.$nextTick(() => {
        const title = to.meta.title
        const { path } = to
        this.addRecentVisitData({ title, path })
      })
      setTimeout(() => {
        this.filterMenu(to)
      }, 500)
    },
  },
  mounted() {
    this.$router.onReady(() => {
      if (this.$route.query.token) {
        setCookie('myToken', this.$route.query.token)
      }
      this.getUserInfo()
      this.getCollegeInfo()
      this.getMenuData()
    })
  },
  methods: {
    filterMenu(to) {
      if (JSON.parse(sessionStorage.getItem('menu'))) {
        this.menuList = JSON.parse(sessionStorage.getItem('menu'))
        this.menuList.map(v => {
          if ((v.children && v.children.length !== 0) || to.path == '/dashbord') {
            v.children.map(k => {
              if (to.path == v.path + '/' + k.path || to.path == '/dashbord') {
                if (
                  JSON.parse(
                    localStorage.getItem(
                      localStorage.getItem('userPhone') + 'visit' + this.clientCode,
                    ),
                  )
                ) {
                  this.arr = JSON.parse(
                    localStorage.getItem(
                      localStorage.getItem('userPhone') + 'visit' + this.clientCode,
                    ),
                  )
                }
                let obj = {
                  name: to.meta.title,
                  path: to.path,
                }
                if (
                  JSON.stringify(this.arr).indexOf(JSON.stringify(obj)) == -1 &&
                  to.path !== '/exception/404'
                )
                  this.arr.unshift(obj)
                if (this.arr.length > 8) {
                  this.arr.pop()
                }
                localStorage.setItem(
                  localStorage.getItem('userPhone') + 'visit' + this.clientCode,
                  JSON.stringify(this.arr),
                )
              }
            })
          }
        })
      } else {
        let obj = {
          name: to.meta.title,
          path: to.path,
        }
        if (
          JSON.stringify(this.arr).indexOf(JSON.stringify(obj)) == -1 &&
          to.path !== '/exception/404'
        )
          this.arr.unshift(obj)
        if (this.arr.length > 8) {
          this.arr.pop()
        }
        localStorage.setItem(
          localStorage.getItem('userPhone') + 'visit' + this.clientCode,
          JSON.stringify(this.arr),
        )
      }
    },
    async addRecentVisitData(params) {
      const [, err] = await to(addRecentVisit(params))
      if (err) return this.$message.warning(err.msg)
    },
    async getMenuData() {
      const [res, err] = await to(getMenu({ clientCode: this.clientCode }))
      if (err) return this.$message.warning(err.msg)
      if (res.data && res.data !== null) {
        setTimeout(() => {
          sessionStorage.setItem('menu', JSON.stringify(res.data))
        }, 100)
      }
    },
    async getCollegeInfo() {
      const [res, err] = await to(getSearchCollegeNews({}))
      if (err) return this.$message.warning(err.msg)
      this.$store.commit(`user/${type.SET_COLLEGE_INFO}`, res.data)
    },
    async getUserInfo() {
      const [res, err] = await to(userInfo({}))
      if (err) return this.$message.warning(err.msg)
      this.$store.commit(`user/${type.SET_USER_INFO}`, res.data)
      localStorage.setItem('userPhone', res.data.phone)
    },
  },
}
</script>
