import Vue from 'vue'
import {
  Rate,
  Image,
  Cascader,
  Checkbox,
  CheckboxGroup,
  Row,
  Col,
  Pagination,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  InputNumber,
  Button,
  Form,
  FormItem,
  Carousel,
  CarouselItem,
  Loading,
  Message,
  Breadcrumb,
  BreadcrumbItem,
  Scrollbar,
  Menu,
  MenuItemGroup,
  Submenu,
  MenuItem,
  Notification,
  Tabs,
  TabPane,
  Steps,
  Step,
  Table,
  Tooltip,
  TableColumn,
  Card,
  DatePicker,
  TimePicker,
  Select,
  Option,
  OptionGroup,
  Upload,
  Tree,
  Transfer,
  Popconfirm,
  Popover,
  MessageBox,
  Radio,
  RadioGroup,
  RadioButton,
  Timeline,
  TimelineItem,
  Progress,
  Switch,
} from 'element-ui'
import '@/styles/element-variables.scss'
const components = {
  Tooltip,
  Rate,
  Image,
  Cascader,
  Checkbox,
  CheckboxGroup,
  Row,
  Col,
  Pagination,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  InputNumber,
  Button,
  Form,
  FormItem,
  Carousel,
  CarouselItem,
  Loading,
  Breadcrumb,
  BreadcrumbItem,
  Scrollbar,
  Menu,
  MenuItemGroup,
  Submenu,
  MenuItem,
  Tabs,
  TabPane,
  Steps,
  Step,
  Table,
  TableColumn,
  Card,
  DatePicker,
  TimePicker,
  Select,
  Option,
  OptionGroup,
  Upload,
  Tree,
  Transfer,
  Popconfirm,
  Popover,
  Radio,
  RadioGroup,
  RadioButton,
  Timeline,
  TimelineItem,
  Progress,
  Switch,
}
Vue.prototype.$message = Message
Vue.prototype.$notify = Notification
Vue.prototype.$confirm = MessageBox.confirm

Object.values(components).forEach(component => {
  Vue.use(component)
})
