/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-30 10:17:43
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-10-15 15:56:08
 */
import Layout from '@/layout'

export default [
  {
    path: '/teacher',
    name: 'Teacher',
    component: Layout,
    redirect: '/teacher/home',
    meta: { title: '讲师管理', icon: 'el-icon-user' },
    children: [
      {
        path: 'home',
        name: 'TeacherHome',
        component: () => import('@/views/teacher/Home/index'),
        meta: { title: '预约讲师', keepAlive: true, level: 1 },
      },
      {
        path: 'list',
        name: 'TeacherList',
        hidden: true,
        component: () => import('@/views/teacher/AboutTeacher/newList'),
        meta: { title: '讲师列表', keepAlive: true },
      },
      {
        path: 'detail',
        name: 'TeacherDetail',
        hidden: true,
        component: () => import('@/views/teacher/AboutTeacher/detail'),
        meta: { title: '讲师详情' },
      },
      {
        path: 'myAboutList',
        name: 'MyAboutList',
        component: () => import('@/views/teacher/MyAbout/myAboutList'),
        meta: { title: '我的预约', level: 1 },
      },
      {
        path: 'myCheck',
        name: 'myCheck',
        component: () => import('@/views/teacher/MyAbout/myCheck'),
        meta: { title: '场次签到' },
      },
      {
        path: 'myAboutDetail',
        name: 'MyAboutDetail',
        hidden: true,
        component: () => import('@/views/teacher/MyAbout/myAboutDetail'),
        meta: { title: '预约详情' },
      },
      {
        path: 'school',
        name: 'School',
        hidden: true,
        component: () => import('@/views/teacher/AboutTeacher/school'),
        meta: { title: '学堂详情' },
      },
      {
        path: 'courseDetail',
        name: 'CourseDetail',
        hidden: true,
        component: () => import('@/views/teacher/AboutTeacher/courseDetail'),
        meta: { title: '课程详情' },
      },
      {
        path: 'witnessDetail',
        name: 'WitnessDetail',
        hidden: true,
        component: () => import('@/views/teacher/AboutTeacher/witnessDetail'),
        meta: { title: '授课见证详情' },
      },
      {
        path: 'trainer',
        name: 'Trainer',
        hidden: true,
        component: () => import('@/views/teacher/InternalTrainer/index'),
        meta: { title: '内训师管理', level: 1 },
      },
      {
        path: 'trainerAdd',
        name: 'TrainerAdd',
        hidden: true,
        component: () => import('@/views/teacher/InternalTrainer/trainerAdd'),
        meta: { title: '添加内训师' },
      },
      {
        path: 'trainerInfo',
        name: 'TrainerInfo',
        hidden: true,
        component: () => import('@/views/teacher/InternalTrainer/trainerInfo'),
        meta: { title: '内训师详情' },
      },
      {
        path: 'trainerTable',
        name: 'TrainerTable',
        hidden: true,
        component: () => import('@/views/teacher/InternalTrainer/trainerTable'),
        meta: { title: '统计报表' },
      },
      {
        path: 'trainerCourseInfo',
        name: 'TrainerCourseInfo',
        hidden: true,
        component: () => import('@/views/teacher/InternalTrainer/trainerCourseInfo'),
        meta: { title: '课程详情' },
      },
      {
        path: 'poster',
        name: 'PosterList',
        hidden: true,
        component: () => import('@/views/teacher/Poster/index'),
        meta: { title: '海报列表' },
      },
      {
        path: 'poster/add',
        name: 'PosterAdd',
        hidden: true,
        component: () => import('@/views/teacher/Poster/design'),
        meta: { title: '制作海报' },
      },
      {
        path: 'poster/:id',
        name: 'PosterEdit',
        hidden: true,
        component: () => import('@/views/teacher/Poster/design'),
        meta: { title: '编辑海报' },
      },
      {
        path: 'recordInfo',
        name: 'RecordInfo',
        component: () => import('@/views/teacher/MyAbout/record/index'),
        meta: { title: '报名记录' },
      },
    ],
  },
]
