<template>
  <div class="pageMain">
    <keep-alive>
      <transition v-if="$route.meta.keepAlive" name="fade-page" mode="out-in">
        <router-view :key="key"></router-view>
      </transition>
    </keep-alive>
    <transition v-if="!$route.meta.keepAlive" name="fade-page" mode="out-in">
      <router-view :key="key"></router-view>
      <!-- <router-view :key="key"></router-view> -->
    </transition>
  </div>
</template>
<script>
export default {
  computed: {
    key() {
      return this.$route.fullPath
    },
  },
}
</script>
